
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getCookieName, setCookie, mime } from '@/utils/utils';

@Component({
    name: "DocumentPage",
})
export default class DocumentPage extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private view = '';
    private isVideo = false;
    private docSource = [];
    private docList = [];
    private moreDocList = [];
    private currentDocInfo: any = {};
    private screenWidth: any = null;
    private activeIndex: any = null;
    private preIndex: any = null;
    private showDialog: boolean = false;
    private quickStartUrl = 'https://shdoc.morewiscloud.com/mcdocs/wis-ide/install-deploy/quickstart/index.html?t=' +
        new Date().getTime();
    private mounted() {
        this.getDocList();
        // this.allowThirdCookie();

        this.screenWidth = window.screen.width || document.body.clientWidth;
        window.onresize = () => {
            return (() => {
                this.screenWidth = window.innerWidth || document.body.clientWidth;
            })();
        };
    }
    @Watch('screenWidth')
    private screenWidthChange(val: any) {
        if (val < 1280) {
            this.docList = this.docSource.slice(0, 3);
            this.moreDocList = this.docSource.slice(3);
        } else if (val < 1600 && val > 1280) {
            this.docList = this.docSource.slice(0, 4);
            this.moreDocList = this.docSource.slice(4);
        } else {
            this.docList = this.docSource.slice(0, 5);
            this.moreDocList = this.docSource.slice(5);
        }
    }
    private allowThirdCookie() {
        const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
        if (userAgent.indexOf("Chrome") > -1 && !(userAgent.indexOf('WOW64') > -1 || mime("type", "application/gameplugin"))) { // 判断是否Chrome浏览器
            const AllowThirdCookie = getCookieName('AllowThirdCookie');
            if (!AllowThirdCookie) {
                this.showDialog = true;
            }
        }
    }
    private setAllowCookie() {
        // window.open('chrome://settings/cookies', '_blank');
        setCookie('AllowThirdCookie', 'allow', 36500);
        this.showDialog = false;
    }
    private handleSelect(key: any) {
        console.log(key);
        this.activeIndex = key;
        const arr = key.split('-');
        const row = this.docSource.find((item: any) => item.id.toString() === arr[0]);
        this.getPermission(row, arr[1]);
    }
    private getDocList() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/document/menu/${this.$route.query.id}`)
        .then((res: any) => {
            this.docSource = res;
            this.docList = res.slice(0, 5);
            this.moreDocList = res.slice(5);
            this.currentDocInfo = this.docList[0];
            if (this.currentDocInfo.documentLink) {
                if (this.$route.query.type === 'quickStart') {
                    this.view = this.quickStartUrl;
                } else {
                    this.view = this.currentDocInfo.documentLink;
                }
                this.isVideo = false;
                this.activeIndex = `${this.$route.query.id}-1`;
                this.preIndex = `${this.$route.query.id}-1`;
            } else {
                this.view = this.currentDocInfo.videoLink;
                this.isVideo = true;
                this.activeIndex = `${this.$route.query.id}-2`;
                this.preIndex = `${this.$route.query.id}-2`;
            }
        });
    }
    private getPermission(row: any, type?: string) {
        this.$httpService.getData({
            documentType: type,
            id: row.id,
        }, `/apiProxy/api/frontend/document/${row.id}/menu`)
        .then((res: any) => {
            this.currentDocInfo = row;
            this.preIndex = this.activeIndex;
            this.view = '';
            this.$nextTick(() => {
                if (type === '1') {
                    this.openDoc(row.documentLink, false);
                } else {
                    this.openDoc(row.videoLink, true);
                }
            });
        }).catch((error: any) => {
            console.log(this.activeIndex, this.preIndex);
            this.activeIndex = this.preIndex;
            if (error.code === 1002017000) {
                // 未登录
                this.$alert('您的账号未登录，请登录后查看！', '提示', {
                    showClose: true,
                    confirmButtonText: '前往登录',
                    callback: (action: any) => {
                        if (action === 'confirm') {
                            // 清除各种缓存数据
                            localStorage.clear();
                            // 跳转到登录页面
                            this.$router
                            .push({
                                path: '/login',
                                query: {
                                    redirect: window.location.href,
                                },
                            }).catch((err) => err);
                        }
                    },
                });
            }
        });
    }
    private openDoc(link: string, isVideo: boolean) {
        this.view = link;
        this.isVideo = isVideo;
    }
    private moreDoc() {
        this.$router.push({
            path: "/documentCenter",
        });
    }
}
